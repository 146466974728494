var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login"
  }, [_c('img', {
    staticClass: "background",
    attrs: {
      "src": _vm.backgroundUrl
    }
  }), _c('span', {
    staticClass: "version-number"
  }, [_vm._v("v" + _vm._s(_vm.versionNumber || '1.0.0'))]), _c('div', {
    staticClass: "aside-login-container"
  }), _c('div', {
    staticClass: "logo-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "line"
  }), _c('span', {
    staticClass: "white--text slogan py-3"
  }, [_vm._v(_vm._s(_vm.$t('login.messageDescription')))])]), _c('div', {
    staticClass: "container-form"
  }, [_c('router-view'), _vm._m(1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "people-logo"
  }, [_c('img', {
    attrs: {
      "src": "/img/full_logo.png"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "brands-container"
  }, [_c('img', {
    staticClass: "logo-cedro",
    attrs: {
      "src": "/img/logo-cedro-white.svg"
    }
  })]);
}]

export { render, staticRenderFns }