import { mapGetters, mapActions } from 'vuex'
import { REMOVE_TOAST_MESSAGE } from 'vuex-toast'
import _ from 'lodash'
import { version } from '../../../../package.json'

export default {
  data () {
    return {
      versionNumber: version
    }
  },
  created () {
    this.backgroundUrl = this.randomBackgorund()
  },
  mounted () {
    const sessionExpiredToast = _.find(this.toastMessages, { text: `${this.$t('login.toast.sessionExpired')}` })
    if (sessionExpiredToast) {
      setTimeout(() => {
        this.removeToast(sessionExpiredToast.id)
      }, 2000)
    }
  },
  computed: {
    ...mapGetters([
      'toastMessages'
    ])
  },
  methods: {
    ...mapActions({
      removeToast: REMOVE_TOAST_MESSAGE
    }),
    randomBackgorund () {
      const randomIndexBackground = Math.floor(Math.random() * 5)

      return `/img/login-background/background-0${randomIndexBackground}.jpg`
    }
  }
}
